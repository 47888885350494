import parse from 'html-react-parser';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const About = (props) => {

  const { about, windowHeight } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="page page--about"
      style={{ height: windowHeight + 'px' }}
    >
      <div className="slideshow__back-link__wrapper">
        <Link to="/" className="slideshow__back-link">&times;</Link>
      </div>
      <div className="container-fluid page--about__inner">
        <div className="row">
          <div className="col-xxs-12">
            <div className="page--about__content">
              {about.content ? parse(about.content) : ''}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
};

export default About;
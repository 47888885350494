import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ProjectsGrid from '../components/ProjectsGrid';
import HomepageFilters from '../components/HomepageFilters';
import { Link, Outlet } from 'react-router-dom';

const Homepage = (props) => {

  const { projects, pathname } = props;
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [activeFilter, setActiveFilter] = useState('all');

  useEffect(() => {
    if (activeFilter === 'all') {
      setFilteredProjects([...projects]);
    } else {
      const filteredProjectsArray = [];
      for (const project of projects) {
        if (activeFilter === 'video' && project.video === true) {
          filteredProjectsArray.push(project);
        } else if (activeFilter === 'photo' && project.photo === true) {
          filteredProjectsArray.push(project);
        }
      }
      setFilteredProjects(filteredProjectsArray);
    }
  }, [projects, activeFilter]);

  return (
    <motion.div className="homepage page">
      <AnimatePresence mode="wait">
        {
          pathname === '/' ?
            <HomepageFilters activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
            :
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="close"
              className="slideshow__back-link__wrapper"
            >
              <Link to="/" className="slideshow__back-link">&times;</Link>
            </motion.div>
        }
      </AnimatePresence>
      <ProjectsGrid {...props} projects={filteredProjects} pathPrefix={'projects'} activeFilter={activeFilter} />
      <Outlet />
    </motion.div>
  )
}

export default Homepage;
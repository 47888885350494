import ProjectSlideshow from './ProjectSlideshow';
import ProjectsGridMobile from './ProjectsGridMobile';
import ProjectsGridDesktop from './ProjectsGridDesktop';

const ProjectsStack = (props) => {
  const { projects, index, numberOfStacks, activeProjectIndex } = props;

  return (
    <div
      className={`projects-grid__stack${activeProjectIndex > -1 ?
          activeProjectIndex % numberOfStacks === index ?
            ' active' : ' inactive' : ''}`
      }
    >
      {
        projects &&
        projects.map(
          (project, projectIndex) => (
            projectIndex % numberOfStacks === index &&
            <ProjectSlideshow {...props} project={project} key={projectIndex} />
          )
        )
      }
    </div>
  )
}

const ProjectsGrid = (props) => {
  const { windowWidth } = props;
  
  if (windowWidth < 768) {
    return (
      <ProjectsGridMobile {...props} />
    );
  } else {
    return (
      <ProjectsGridDesktop {...props} />
    );
  }
};

export default ProjectsGrid;
export { ProjectsStack };
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Content from './components/Content';
import { fetchCockpitData } from './utils/cockpit';

const App = (props) => {

  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  
  const [imageQuality, setImageQuality] = useState(60);
  const [imageLongestSide, setImageLongestSide] = useState(2048);
  const [client, setClient] = useState({});
  const [clientProjects, setClientProjects] = useState([]);
  const [isSignedIn, setIsSignedIn] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [projects, setProjects] = useState([]);
  const [about, setAbout] = useState({});
  const [previewItem, setPreviewItem] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (location.pathname.indexOf('/page/') !== 0) {
      setCurrentPage({});
    }
  }, [location.pathname]);

  useEffect(() => {
    const textFromStorage = sessionStorage.getItem('client logged in');
    const handleFetchClient = (data) => {
      if (data[0]) {
        setClient(data[0]);
      }
    };
    if (textFromStorage) {
      if (textFromStorage) {
        fetchCockpitData('clients', handleFetchClient, true, null, { _id: textFromStorage });
      }
    }
  }, []);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 3000);
      
    return () => {
      clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    if (client?._id && client.projects?.length > 0) {
        const pageIdsArray = [];

        if (client?.projects?.length > 0) {

          for (let page of client?.projects) {
            pageIdsArray.push({
              _id: page._id
            });
          }

          fetchCockpitData(
            'projects',
            setClientProjects,
            true,
            {
              name: true,
              gallery: true,
              thumbnail: true
            },
            {
              published: true,
              $or: [...pageIdsArray]
            }
          );
        }
    }
  }, [client, client.projects]);

  const handleFetchProjects = useCallback((data) => {
    const projectsArray = [...data]
    projectsArray.sort((a,b) => (a._o > b._o) ? 1 : ((b._o > a._o) ? -1 : 0));
    setProjects(projectsArray);
  }, []);

  useEffect(() => {
    fetchCockpitData('options', (response) => {
      if (response.imageQuality) {
        setImageQuality(response.imageQuality);
      }
      if (response.imageLongestSide) {
        setImageLongestSide(response.imageLongestSide);
      }
      fetchCockpitData('projects', handleFetchProjects, true, null, { published: true, showOnHomepage: true });
      fetchCockpitData('about', setAbout, false, null);
    }, false, null);
  }, [handleFetchProjects]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  useEffect(() => {

  }, []);

  return (
    <Content
      {...props}
      navigate={navigate}
      location={location}
      pathname={location.pathname}
      {...windowDimensions}
      projects={projects}
      setProjects={setProjects}
      previewItem={previewItem}
      setPreviewItem={setPreviewItem}
      client={client}
      setClient={setClient}
      isSignedIn={isSignedIn}
      setIsSignedIn={setIsSignedIn}
      about={about}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      clientProjects={clientProjects}
      setClientProjects={setClientProjects}
      isLoaded={isLoaded}
      imageQuality={imageQuality}
      imageLongestSide={imageLongestSide}
    />
  );
}

export default App;
import { AnimatePresence, motion } from 'framer-motion';
import { Link, Outlet } from 'react-router-dom';
import ProjectsGrid from './ProjectsGrid';

const PasswordProtectedPageContent = (props) => {

  const { clientProjects, windowWidth, windowHeight, pathname } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="page--client-hub"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      <AnimatePresence>
        {
          pathname !== '/portal' &&
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="close"
            className="slideshow__back-link__wrapper"
          >
            <Link to="/portal" className="slideshow__back-link">&times;</Link>
          </motion.div>
        }
      </AnimatePresence>
      <ProjectsGrid {...props} activeFilter={''} projects={clientProjects} pathPrefix={'portal'} />
      <Outlet />
    </motion.div>
  );
};

export default PasswordProtectedPageContent;
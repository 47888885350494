import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { useState, useRef } from 'react';

const VideoFilePlayer = (props) => {

  const { url, visible, isActiveSlideshow } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const [muted, setMuted] = useState(true);
  const player = useRef();

  useEffect(() => {
    if (visible === true) {
      player.current.play()
      .catch(err => console.error(err))
    } else {
      player.current.pause();
    }
  }, [visible]);

  useEffect(() => {
    if (isActiveSlideshow === false || visible === false) {
      setMuted(true);
    } else {
      player.current.currentTime = 0;
    }
  }, [isActiveSlideshow, visible]);

  if (url) {
    return (
      <div
        className={`video-player--file`}
        style={{
          pointerEvents: isPlaying === true ? 'none' : 'auto'
        }}
      >
          <video
            ref={player}
            src={url}
            className="video-player__player"
            playsInline={true}
            onPlay={() => {
              setIsPlaying(true);
              if (isActiveSlideshow === true) {
                setMuted(false);
              } else {
                setMuted(true);
              }
            }}
            onPause={() => {
              setIsPlaying(false);
              setMuted(true);
            }}
            volume={1}
            muted={muted}
            loop={true}
            onClick={() => player.current.play().then().catch(error => console.log(error))}
          />
        <AnimatePresence>
          {
            isPlaying === false &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: .2 }}
              className="video-player__play-icon"
            />
          }
        </AnimatePresence>
      </div>
    )
  } else {
    return null;
  }
}

export default VideoFilePlayer;
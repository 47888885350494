import { useRef, Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import ProjectSlideshow from './ProjectSlideshow';

const lerp = (start, end, amount) => {
  return (1 - amount) * start + amount * end;
}

const ProjectsStack = (props) => {
  const { projects, index, numberOfStacks, activeProjectIndex } = props;

  return (
    <div
      className={`projects-grid__stack${activeProjectIndex > -1 ?
          activeProjectIndex % numberOfStacks === index ?
            ' active' : ' inactive' : ''}`
      }
    >
      {
        projects &&
        projects.map(
          (project, projectIndex) => (
            projectIndex % numberOfStacks === index &&
            <ProjectSlideshow {...props} project={project} key={projectIndex} />
          )
        )
      }
    </div>
  )
}

const ProjectsGridMobile = (props) => {
  const { projects, pathPrefix, activeFilter, windowWidth, windowHeight } = props;
  const [activeProjectSlideshow, setActiveProjectSlideshow] = useState({});
  const [activeProjectIndex, setActiveProjectIndex] = useState(-1);
  const location = useLocation();
  const { pathname } = location;
  const projectsGrid = useRef();

  useEffect(() => {
    let activeProject = {};
    let activeProjectIndexNumber = -1;
    const urlId = pathname.replace(`/${pathPrefix}/`, ``);
    for (let i = 0; i < projects.length; i++) {
      const project = projects[i];
      if (project._id === urlId) {
        activeProject = {...project};
        activeProjectIndexNumber = i;
      }
    }
    setActiveProjectSlideshow(activeProject);
    setActiveProjectIndex(activeProjectIndexNumber);
  }, [projects, pathname, pathPrefix]);

  useEffect(() => {
    let raf;
    if (projectsGrid.current && activeProjectIndex > -1) {
      const target = windowWidth < windowHeight ? (activeProjectIndex * ((windowWidth * 0.5 * 1.5 + 180))) :
        (activeProjectIndex * Math.min(windowHeight, windowWidth * 0.8)) + 60;

      projectsGrid.current.overflowY = 'scroll';
      projectsGrid.current.pointerEvents = 'none';

      const scroll = () => {
        projectsGrid.current.scrollTop = lerp(projectsGrid.current.scrollTop, target, 0.2);
        if (projectsGrid.current.scrollTop === target) {
          projectsGrid.current.overflowY = null;
          projectsGrid.current.pointerEvents = null;
        } else {
          raf = requestAnimationFrame(scroll);
        }
      }

      raf = requestAnimationFrame(scroll);
    }

    return () => {
      cancelAnimationFrame(raf);
    }
  }, [activeProjectIndex, windowWidth, windowHeight]);

  return (
    <Fragment>
      <div
        className={`projects-grid container-fluid no-padding${activeProjectIndex > -1 ? ' project-active' : ''}`}
        ref={projectsGrid}
        style={{
          height: windowHeight + 'px'
        }}
      >
        <AnimatePresence mode="wait">
          <motion.div
            className="projects-grid__row"
            key={activeFilter ?? 'no-filters'}
          >
            <ProjectsStack
              {...props}
              projects={projects}
              index={0}
              numberOfStacks={1}
              activeProjectSlideshow={activeProjectSlideshow}
              pathPrefix={pathPrefix}
              activeProjectIndex={activeProjectIndex}
              projectsGrid={projectsGrid}
            />
          </motion.div>
        </AnimatePresence>
      </div>
    </Fragment>
  )
};

export default ProjectsGridMobile;
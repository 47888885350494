import { AnimatePresence, motion } from 'framer-motion';
import { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { cockpitUrl } from '../utils/cockpit';
import { useEffect } from 'react';

const VideoPlayer = (props) => {

  const { url, visible, isActiveSlideshow } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const [isActuallyPlaying, setIsActuallyPlaying] = useState(false);
  const [aspectRatio] = useState(0.5625);
  const [isMuted, setIsMuted] = useState(true);

  const player = useRef();

  useEffect(() => {
    if (visible === true && isActiveSlideshow === true) {
      setIsPlaying(true);
      player.current.seekTo(0);
    } else if (visible === true && isActiveSlideshow === false) {
      setIsPlaying(true);
      setIsMuted(true);
      console.log(visible, isActiveSlideshow, 'play');
    } else {
      console.log(visible, isActiveSlideshow, 'pause');
      setIsPlaying(false);
    }
  }, [visible, isActiveSlideshow]);

  if (url) {
    return (
      <div
        className={`video-player`}
        style={{
          paddingBottom: 100 * aspectRatio + '%',
          pointerEvents: isActuallyPlaying === true && isMuted === false ? 'none' : 'all'
        }}
        onClick={() => {
          if (visible === true && isActiveSlideshow === true) {
            setIsMuted(false);
          }
        }}
      >
        <div className="video__wrapper"
        style={{
          pointerEvents: isActuallyPlaying === true ? 'none' : 'all'
        }}>
          <ReactPlayer
            ref={player}
            url={url.indexOf('/storage') === 0 ? cockpitUrl + '/' + url : url}
            className="video-player__player"
            playsinline={true}
            playing={isPlaying}
            controls={false}
            volume={1}
            muted={isMuted}
            height={'100%'}
            width={'100%'}
            loop={true}
            onPlay={() => {
              setIsActuallyPlaying(true);
            }}
            onPause={() => {
              setIsActuallyPlaying(false);
              setIsPlaying(false);
            }}
            onClick={() => {
              if (isActuallyPlaying === false) {
                setIsPlaying(false);
                setTimeout(() => {
                  setIsPlaying(true);
                }, 100);
              } else {
                setIsMuted(false);
              }
            }}
            onError={(error) => {
              console.log("the error is ", error);
              setIsActuallyPlaying(false);
              setIsPlaying(false)
            }}
          />
        </div>
        <AnimatePresence>
          {
            isActuallyPlaying === false &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: .2 }}
              className="video-player__play-icon"
              onClick={() => {
                if (isActuallyPlaying === false) {
                  setIsPlaying(false);
                  setTimeout(() => {
                    setIsPlaying(true);
                  }, 100);
                }
              }}
            />
          }
        </AnimatePresence>
      </div>
    )
  } else {
    return null;
  }
}

export default VideoPlayer;
import { Link } from 'react-router-dom';

const Header = (props) => {

  return (
    <header className="header container-fluid">
      <div className="row">
        <div className="col-xxs-6 col-sm-4 align--left">
          <Link to="/" className="header__link">Kaj Jefferies</Link>
        </div>
        <div className="col-xxs-6 col-sm-4 offset-sm-4 align--right">
          <Link to="/about" className="header__link">About</Link>
        </div>
      </div>
    </header>
  )
}

export default Header;
import { useState, useEffect } from 'react';
import { cockpitUrl, getImage } from '../utils/cockpit';

const LazyloadImage = (props) => {

  const { image, q, w, h, isBackgroundImage, desaturate, callback, className, backgroundPositionX, backgroundPositionY } = props;
  const [imageSrc, setImageSrc] = useState('');
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  useEffect(() => {
    let img;
    const onImageLoad = () => {
      setImageIsLoaded(true);
    }

    if (image?.path) {
      if (image.mime !== 'image/gif') {
        const path = image.path;
        getImage(
          path.indexOf(`/storage/uploads/`) !== 0 ? `/storage/uploads${path}` : path,
          w ?? 2048,
          h ?? 2048,
          q ?? 30,
          (imageUrl) =>  setImageSrc(imageUrl)
        );
      } else {
        const url = `${cockpitUrl}/storage/uploads${image.path}`
        setImageSrc(url);
      }
    }

    return () => {
      if (img) {
        img.removeEventListener('load', onImageLoad);
      }
    }
  }, [image, q, w, h, desaturate]);

  useEffect(() => {
    if (imageIsLoaded === true) {
      callback && callback();
    }
  }, [imageIsLoaded, callback]);

  if (image?.path && image.path !== '') {
    return (
      <img
        className={`image--lazyload${imageIsLoaded === true ? ' loaded' : ''}${isBackgroundImage === true ? ' background' : ''}${className ? ` ` + className : ''}`}
        src={imageSrc}
        alt={image.title}
        style={{
          objectPosition: backgroundPositionX && backgroundPositionY ? `${backgroundPositionX}% ${backgroundPositionY}%` : null,
          width: imageIsLoaded === false && isBackgroundImage !== true ? '100%' : null,
          paddingBottom: imageIsLoaded === false && isBackgroundImage !== true ? image.width / image.height * 100 + '%' : null,
          height: imageIsLoaded === false && isBackgroundImage !== true ? 0 : null,
          position: imageIsLoaded === false && isBackgroundImage !== true ? 'relative' : null,
        }}
        onLoad={() => setImageIsLoaded(true)}
        loading="lazy"
      />
    );
  } else {
    return null
  }
}

export default LazyloadImage;
import { AnimatePresence, motion } from 'framer-motion';
import { cockpitUrl } from '../utils/cockpit';
import LazyloadImage from './LazyloadImage';
import VideoFilePlayer from './VideoFilePlayer';
import VideoPlayer from './VideoPlayer';

const ProjectSlideshowSlide = (props) => {

  const { item, isActive, isVisible, activeProjectSlideshow, project, windowWidth, windowHeight, imageQuality, imageLongestSide } = props;

  return (
    <div
      className="project__slideshow__slide"
      style={{
        opacity: isVisible ? 1 : 0,
        height: windowWidth < windowHeight ?
          windowWidth * 0.5 * 1.5 + 180 + 'px'
        :
          (Math.min(windowWidth * 0.8, windowHeight) - 120) + 'px'
      }}
    >
      {
        item.field.name === 'image' &&
        item.value?.image &&
        <LazyloadImage
          q={imageQuality}
          w={imageLongestSide}
          h={imageLongestSide}
          image={item.value.image}
        />
      }
      {
        item.field.name === 'video' &&
        item.value?.video?.path &&
        <VideoFilePlayer
          url={cockpitUrl + '/storage/uploads' + item.value.video.path}
          visible={isActive}
          isActiveSlideshow={activeProjectSlideshow._id === project._id ? true : false}
        />
      }
      {
        item.field.name === 'videoUrl' &&
        item.value?.video &&
        <VideoPlayer
          url={item.value.video}
          visible={isActive}
          isActiveSlideshow={activeProjectSlideshow._id === project._id ? true : false}
        />
      }
      <AnimatePresence>
        {
          activeProjectSlideshow._id !== project._id && isActive === true &&
          <motion.div
            initial={{ opacity: 0, scaleY: 0 }}
            animate={{ opacity: 1, scaleY: 1 }}
            exit={{ opacity: 0, scaleY: 0 }}
            
            className="project-info"
          >
            <h2 className="project-thumbnail__title">{project.name}</h2>
            <p className="project-thumbnail__client-name">{project.client}</p>
          </motion.div>
        }
      </AnimatePresence>
    </div>
  );
};

export default ProjectSlideshowSlide;
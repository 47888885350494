import PasswordProtectedPageLoginForm from '../components/PasswordProtectedPageLoginForm';
import PasswordProtectedPageContent from '../components/PasswordProtectedPageContent';

const PasswordProtectedPage = (props) => {

  const { client } = props;

  return (
    <>
      {
        !client.name ?
        <PasswordProtectedPageLoginForm {...props} />
        :
        <PasswordProtectedPageContent {...props} />
      }
    </>
  )
}

export default PasswordProtectedPage;
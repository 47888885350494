import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ProjectSlideshowSlide from './ProjectSlideshowSlide';
import { useState } from 'react';

const ProjectSlideshow = (props) => {
  const { project, activeProjectSlideshow, pathPrefix, windowWidth, windowHeight } = props;
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <motion.div
      layout
      className={`project__slideshow__wrapper${activeProjectSlideshow._id === project._id ? ' active' : activeProjectSlideshow._id ? ' inactive' : ''}
      ${activeSlideIndex === 0 ? ' first-slide-is-active' : ''}
      ${activeSlideIndex === project?.gallery?.length - 1 ? ' last-slide-is-active' : ''}`}
      key={project._id}
      style={{
        height:
          // activeProjectSlideshow._id === project._id ?
          // windowHeight - 120 + 'px'
          // :
          windowWidth < windowHeight ?
          windowWidth * 0.5 * 1.5 + 180 + 'px'
        :
          (Math.min(windowWidth * 0.8, windowHeight)) + 'px'
      }}
    >
      <Link to={`/${pathPrefix}/${project._id}`} className="project-thumbnail">
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="project__slideshow"
          watchSlidesProgress={true}
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={({activeIndex}) => setActiveSlideIndex(activeIndex)}
        >
          {
            project.gallery &&
            project.gallery[0] &&
            project.gallery.map((item, index) => (
              <SwiperSlide key={index}>
                {({ isActive, isVisible }) => (
                  <ProjectSlideshowSlide
                    {...props}
                    item={item}
                    index={index}
                    isActive={isActive}
                    isVisible={isVisible}
                    project={project}
                    activeProjectSlideshow={activeProjectSlideshow}
                    windowWidth={windowWidth}
                    windowHeight={windowHeight}
                  />
                )}
              </SwiperSlide>
            ))
          }
        </Swiper>
      </Link>
      {
        project.gallery && activeProjectSlideshow._id === project._id &&
        <div className="slideshow-numbers">
          {activeSlideIndex + 1}/{project.gallery.length}
        </div>
      }
    </motion.div>
  )
};

export default ProjectSlideshow;
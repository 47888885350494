import { Routes, Route } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Header from './Header';
import Preview from '../utils/Preview';
import PasswordProtectedPage from '../views/PasswordProtectedPage';
import Homepage from '../views/Homepage';
import About from '../views/About';

const Content = (props) => {

  const { location, pathname, windowWidth, windowHeight, clientProjects, previewItem, projects, isLoaded } = props;

  return (
    <>
      <Header {...props} />
      <div
        className={`wrapper`}
        style={{
          width: windowWidth + 'px'
        }}
      >
        <Preview {...props} />
        {
          !previewItem.collection &&
          <AnimatePresence exitBeforeEnter>
            {
              <motion.main
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="main"
              >
                {
                  !previewItem.collection &&
                  <AnimatePresence exitBeforeEnter initial={false}>
                    <Routes location={location} key={pathname.indexOf('/portal') === 0 ? 'protected' : pathname === '/about' ? 'about' : 'main'}>
                      <Route
                        key={`portal`}
                        path={`portal`}
                        element={<PasswordProtectedPage {...props} />}
                      >
                        {
                          clientProjects &&
                          clientProjects.map(project => (
                            <Route key={project._id} path={`${project._id}`} element={<></>} />
                          ))
                        }
                      </Route>
                      <Route
                        path="/about"
                        element={<About {...props} />}
                      />
                      <Route
                        path="/"
                        element={<Homepage {...props} />}
                      >
                        {
                          projects &&
                          projects.map(project => (
                            <Route key={project._id} path={`/projects/${project._id}`} element={<></>} />
                          ))
                        }
                      </Route>
                      <Route
                        path="*"
                        element={<div className="page--404"><p>Page not found</p></div>}
                      />
                    </Routes>
                  </AnimatePresence>
                }
              </motion.main>
            }
          </AnimatePresence>
        }
      </div>
      <AnimatePresence>
        {
          isLoaded === false &&
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="loader"
            style={{
              width: windowWidth + 'px',
              height: windowHeight + 'px'
            }}
          ><h1 className="title">Kaj Jefferies</h1></motion.div>
        }
      </AnimatePresence>
    </>
  )
}

export default Content;
import { motion } from 'framer-motion';

const HomepageFilters = ({ activeFilter, setActiveFilter }) => (
  <motion.header
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="homepage__filters"
  >
    {
      (['photo', 'video', 'all'])
        .map((item) => (
          <button
            className={`homepage__filter${activeFilter === item ? ' active' : ''}`}
            key={item}
            onClick={() => setActiveFilter(item)}
          >{item}</button>
        ))
    }
  </motion.header>
);

export default HomepageFilters;